import React, { Fragment, useEffect, useState } from 'react'
import { formatAmount, useCart, useShippingOptions } from 'medusa-react'
import { Flex, Text } from '@chakra-ui/react'
import { Region } from '@medusajs/medusa'
import { useResponsive } from '@common/hooks'
import { useReel } from '@lib/context/reel-context'

const NotiFreeShipping = () => {
  const { cart } = useCart()
  const [freeShipping, setFreeShipping] = useState(8000)
  const { isOpen } = useReel()

  const { lgMax } = useResponsive()

  const { shipping_options } = useShippingOptions({
    region_id: cart?.region_id
  })

  useEffect(() => {
    if (shipping_options !== undefined && cart !== undefined) {
      const option = shipping_options.find(
        (so) =>
          so.name.toLowerCase().trim().replace(' ', '_') === 'free_shipping'
      )

      if (option != null) {
        const min = option.requirements.find((r) => r.type === 'min_subtotal')
          ?.amount
        setFreeShipping(min ?? 8000)
      }
    }
  }, [shipping_options, cart])

  const displayText = (total: number, region: Region) => {
    if (total >= freeShipping) {
      return (
        <Text
          className="px-2 text-center"
          color={'white'}
          textStyle={'notiShippingText'}
        >
          Congratulations! You are qualified for free shipping
        </Text>
      )
    } else {
      return (
        <Text
          className="px-2 text-center"
          color={'white'}
          textStyle={'notiShippingText'}
        >
          You're only{' '}
          {formatAmount({
            amount: freeShipping - total,
            region,
            includeTaxes: false
          })}{' '}
          away to enjoy Free Delivery
        </Text>
      )
    }
  }

  return (
    <Fragment>
      {cart != null &&
        cart.total != null &&
        cart.items.length > 0 &&
        !isOpen && (
          <Flex
            position={'fixed'}
            left={0}
            top={0}
            w={'100%'}
            zIndex={2000}
            alignItems={'start'}
            justifyContent={'center'}
            px={lgMax ? 0.5 : 4}
          >
            <Flex
              w={'lg'}
              bg={'#626262'}
              color={'white'}
              py={lgMax ? 1 : 2}
              borderBottomRadius={'3xl'}
              justifyContent={'center'}
            >
              {displayText(
                cart.total - (cart.shipping_total ?? 0),
                cart.region
              )}
            </Flex>
          </Flex>
        )}
    </Fragment>
  )
}

export default NotiFreeShipping
