import React, { useEffect, useState } from 'react'
import { listFacebookLivestreams } from '@lib/util/facebook-livestream-handler'
import { useQuery } from '@tanstack/react-query'
import { Button, Stack } from '@chakra-ui/react'
import { CloseIcon } from '@chakra-ui/icons'
import { useResponsive } from '@common/hooks'
import { LivesreamIcon } from '@icons/livestream-icon'

const FacebookLivesream = () => {
  const [link, setLink] = useState('')
  const [isOpen, setIsOpen] = useState(true)

  const { smMax, lgMax } = useResponsive()

  const { data: FbLivestream } = useQuery(
    ['list-facebook-livestreams'],
    async () => await listFacebookLivestreams()
  )

  useEffect(() => {
    if (FbLivestream !== undefined) {
      let linkFb = ''

      for (let i = 0; i < FbLivestream.data.length; i++) {
        const fbLs = FbLivestream.data[i]

        const time = new Date().getTime()
        const timeStart = new Date(fbLs.start).getTime()
        const timeEnd = new Date(fbLs.end).getTime()

        if (timeStart <= time && time <= timeEnd) {
          linkFb = fbLs.link
          break
        }
      }

      setLink(linkFb)
    }
  }, [FbLivestream])

  return (
    <Stack
      position={'fixed'}
      top={lgMax ? '75%' : smMax ? '40%' : '50%'}
      left={lgMax ? '35%' : smMax ? '0%' : '60%'}
      zIndex={'100'}
    >
      {isOpen && link.length > 0 && (
        <Stack position={'relative'}>
          <div
            className="fb-video"
            data-href={link}
            data-width="500"
            data-show-text="false"
            data-allowfullscreen="true"
            data-autoplay="true"
            data-show-captions="true"
          ></div>
          <Button
            position={'absolute'}
            top={-1}
            bg={'transparent'}
            color={'white'}
            onClick={() => {
              setIsOpen(!isOpen)
            }}
            _hover={{
              bg: 'transparent'
            }}
          >
            <CloseIcon />
          </Button>
        </Stack>
      )}
      {/* {!isOpen && (
        <Stack position={'relative'} left={'-100%'}>
          <LivesreamIcon />
        </Stack>
      )} */}
    </Stack>
  )
}

export default FacebookLivesream
