import React, { Fragment, useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import { Flex, Text } from '@chakra-ui/react'
import { CloseIcon } from '@chakra-ui/icons'
import { useResponsive } from '@common/hooks'
import { useQuery } from '@tanstack/react-query'
import { listAnnouncements } from '@lib/util/announcement-handler'
import { useCart } from 'medusa-react'
import { expiredDate, startDate } from '@lib/util/date'
import { IconPropsType } from '@icons/icon-props'

const LogoIcon = dynamic<IconPropsType>(
  () => import('@icons/logo-icon').then((mod) => mod.LogoIcon),
  {
    ssr: false
  }
)

const Announcement = () => {
  const { smMax } = useResponsive()

  const [showAnnouncement, setShowAnnouncement] = useState<boolean>(true)
  const [announcement, setAnnouncement] = useState<any | null>(null)

  const { data: announcements } = useQuery(
    ['list-announcements'],
    async () => await listAnnouncements()
  )

  const { cart } = useCart()

  useEffect(() => {
    if (cart?.region_id && announcements?.data?.length > 0) {
      const announcementFilters = announcements?.data?.filter((an: any) => {
        if (an.start_at != null && an.end_at != null) {
          if (startDate(an.start_at) && !expiredDate(an.end_at)) {
            return an
          }
        } else {
          return an
        }
      })

      const a = announcementFilters[announcementFilters.length - 1]

      if (a != null) {
        if (a.region === 'all') {
          setAnnouncement(a)
        } else if (a.region === cart.region_id) {
          setAnnouncement(a)
        }
      }
    }
  }, [cart, announcements])

  return (
    <Fragment>
      {announcement && (
        <Flex
          position={'fixed'}
          left={0}
          bottom={0}
          w={'100%'}
          bg={'bgBold'}
          zIndex={100}
          py={5}
          alignItems={'center'}
          justifyContent={'center'}
          gap={8}
          display={showAnnouncement ? 'flex' : 'none'}
        >
          {!smMax && <LogoIcon width={50} height={34} />}
          <Flex gap={5} alignItems={'center'}>
            <Flex gap={2}>
              {!smMax && (
                <Text size={'sm'} fontWeight={600}>
                  Announcement:{' '}
                </Text>
              )}
              <Text size={'sm'}>{announcement.description}</Text>
            </Flex>
            <CloseIcon
              boxSize={3}
              cursor={'pointer'}
              onClick={() => {
                setShowAnnouncement(false)
              }}
            />
          </Flex>
        </Flex>
      )}
    </Fragment>
  )
}

export default Announcement
