import { useEffect, useMemo, useState } from 'react'
import {
  Button,
  ButtonProps,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { useStore } from '@lib/context/store-context'
import { useRegions } from 'medusa-react'
import ReactCountryFlag from 'react-country-flag'
import { listSaleChannelsByRegionId } from '@lib/util/region-sale-channel-handler'
import { SalesChannel } from '@medusajs/medusa'

type CountryOption = {
  country: string
  region: string
  label: string
}

const CountryItem = ({
  country,
  color // textColor
}: {
  country: string
  color?: string
}) => {
  return (
    <Flex gap={2} alignItems="center" justifyContent="center">
      {country && (
        <ReactCountryFlag
          svg
          countryCode={country}
          alt={`${country} flag`}
          style={{
            width: '20px',
            height: '20px'
          }}
        />
      )}
      <Text
        textStyle="JmllinkItem"
        mt={1}
        color={color ?? 'white'}
        textAlign="center"
      >
        {country.toUpperCase()}
      </Text>
    </Flex>
  )
}

interface PropsType {
  bg?: string
  color?: string
  textColor?: string
}

const JmlCountrySelectNav = ({
  bg,
  color = 'white',
  textColor,
  ...rest
}: PropsType & ButtonProps) => {
  const { countryCode, setRegion } = useStore()
  const { regions } = useRegions()
  const [current, setCurrent] = useState<CountryOption | undefined>(undefined)

  const options: CountryOption[] | undefined = useMemo(() => {
    return regions
      ?.map((r) => {
        return r.countries.map((c) => ({
          country: c.iso_2,
          region: r.id,
          label: c.display_name
        }))
      })
      .flat()
  }, [regions])

  useEffect(() => {
    if (countryCode) {
      const option = options?.find((o) => o.country === countryCode)
      setCurrent(option)
    }
  }, [countryCode, options])

  const handleChange = async (option?: CountryOption) => {
    if (option) {
      let salesChannels = await listSaleChannelsByRegionId(option?.region)
      const host = process.env.NEXT_PUBLIC_SITE || 'jml'

      // Filter sales channels by host
      salesChannels = salesChannels.filter((sc: SalesChannel) =>
        sc.name.toLowerCase().includes(host)
      )

      const salesChannelIds = salesChannels.map((sc: SalesChannel) => sc.id)

      setRegion(option.region, option.country, salesChannelIds)
    }
  }

  return (
    <Menu>
      <MenuButton
        p={2}
        mt={0}
        as={Button}
        rightIcon={<ChevronDownIcon color={color} />}
        h={'auto'}
        w={'auto'}
        bg={bg || '#01adee !important'}
        _hover={{
          bg: bg || '#01adee !important',
          border: 0,
          boxShadow: '0 0 0 0'
        }}
        _focus={{
          bg: bg || '#01adee !important',
          border: 0,
          boxShadow: '0 0 0 0'
        }}
        {...rest}
      >
        {current ? (
          <CountryItem country={current.country} color={textColor} />
        ) : countryCode ? (
          <CountryItem
            country={
              options?.find((o) => o.country === countryCode)?.country || ''
            }
            color="black"
          />
        ) : (
          ''
        )}
      </MenuButton>
      <MenuList color={color} w="100px">
        {options?.map((o) => {
          return (
            <MenuItem
              onClick={() => {
                handleChange(o)
              }}
              key={o.country}
            >
              <CountryItem country={o.country} color="black" />
            </MenuItem>
          )
        })}
      </MenuList>
    </Menu>
  )
}

export default JmlCountrySelectNav
