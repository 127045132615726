import { Fragment, useState } from 'react'
import dynamic from 'next/dynamic'
import {
  AspectRatio,
  Box,
  Container,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  IconButton,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { CloseIcon, HamburgerIcon, RepeatIcon } from '@chakra-ui/icons'
import { CartIcon, SearchIcon, UserIcon } from '@icons/index'
import Link from 'next/dist/client/link'
import { useResponsive } from '@common/hooks'
import RouterUrl from '@constants/router.constant'
import AuthFilter from '@components/AuthFilter'
import { useCart } from 'medusa-react'
import { useAccount } from '@lib/context/account-context'
import JmlSearch from './jml-search'
import useAdvocado from '@common/hooks/use-advocado'
import { FilterQueryParams } from '@constants/filter.constant'
import Image from 'next/image'
import JmlCountrySelectNav from './jml-country-select-nav'
import { JmlCartIcon } from '@icons/jml-cart-icon'

const HabbitPointIcon = dynamic(() => import('@icons/habbit-point-icon'), {
  ssr: false
})

interface JmlNavbarPropsType {
  navItems: any[]
  name?: string
  color?: string
  productSection: any
  onRedirect: () => void
  brand?: any
}

const NavItems = ({
  items,
  color,
  brand,
  onClose
}: {
  items: any[]
  color?: string
  brand?: any
  onClose?: () => void
}) => {
  return (
    <Fragment>
      {items.map((item: any) => {
        const link =
          item.label === 'Shop' && brand != null
            ? `${item.href as string}?${FilterQueryParams.BRANDS}=${
                brand.handle as string
              }`
            : item.href

        return (
          <Link
            key={item.label}
            href={link}
            onClick={() => {
              if (onClose) {
                onClose()
              }
            }}
          >
            <Text
              textStyle="linkItem"
              py={0.5}
              color={color ? 'white' : 'black'}
              fontWeight={600}
            >
              {item.label}
            </Text>
          </Link>
        )
      })}
    </Fragment>
  )
}

const CountrySelectNavCustom = ({ color }: { color?: string }) => (
  <JmlCountrySelectNav
    bg={color ? `${color} !important` : undefined}
    color={color ? 'white' : undefined}
    textColor={color ?? 'white'}
  />
)

export default function JmlNavbar({
  navItems,
  color,
  productSection,
  onRedirect,
  brand
}: JmlNavbarPropsType) {
  const { xlMax, mdMax } = useResponsive()
  const { isOpen, onClose, onToggle } = useDisclosure()
  const { totalItems } = useCart()
  const { customer } = useAccount()

  const { handleLogout, avocado } = useAccount()

  const [isSearch, setIsSearch] = useState(false)

  const { verifyAdvocado, loadAdvocadoPDPAModal, loadAdvocadoSignupModal } =
    useAdvocado()

  return (
    <Box w={'100%'} zIndex={1000} position="fixed" top={0}>
      <Box bg={color ?? 'white'} px={{ lg: 2 }} w={'100%'}>
        <Container px={0}>
          <Box py={0} color={'black !important'}>
            <Container>
              <Flex
                minH={mdMax ? '78px' : '96px'}
                alignItems="center"
                justifyContent="space-between"
                gap={5}
              >
                {/* Hamburger Icon */}
                <Flex display={{ base: 'flex', md: 'none' }}>
                  <IconButton
                    onClick={onToggle}
                    icon={
                      isOpen ? (
                        <CloseIcon w={5} h={5} />
                      ) : (
                        <HamburgerIcon w={8} h={8} />
                      )
                    }
                    color={color ? 'white !important' : 'black !important'}
                    variant={'ghost'}
                    aria-label={'Toggle Navigation'}
                    _hover={{
                      bg: color ?? 'bg'
                    }}
                  />
                </Flex>

                {/* Logo */}
                <Flex
                  alignSelf="center"
                  justifyContent={{
                    base: 'center',
                    sm: 'center',
                    md: 'center',
                    lg: 'initial'
                  }}
                  w={{
                    lg: '240px',
                    md: '200px',
                    sm: '180px',
                    base: '140px'
                  }}
                  maxH={{ lg: '66x' }}
                  cursor={'pointer'}
                  onClick={onRedirect}
                  aspectRatio={379 / 108}
                >
                  <AspectRatio w="100%" ratio={300 / 64} position="relative">
                    <Image
                      src={'/images/jml-logo.png'}
                      fill
                      alt="habbit-logo-icon"
                    />
                  </AspectRatio>
                </Flex>

                {/* Search Bar */}
                <Flex
                  flex={1}
                  gap={5}
                  paddingX={{
                    base: 0,
                    sm: 0,
                    md: 0,
                    lg: 20
                  }}
                  alignItems="center"
                  position="relative"
                  display={{
                    base: 'none',
                    md: 'flex'
                  }}
                >
                  <JmlSearch
                    w={'100%'}
                    colorIcon={color ? 'white' : 'black'}
                    colorText={color ? 'white' : 'black'}
                  />
                </Flex>

                {/* Header Actions */}
                <Flex
                  alignItems="center"
                  color={color ? 'white !important' : 'black !important'}
                >
                  <Flex
                    gap={{ lg: customer ? 3 : 8, xl: 8, '2xl': 8 }}
                    flexDirection={'row'}
                    alignItems={'center'}
                  >
                    <Flex
                      gap={{
                        base: 3,
                        md: 5
                      }}
                      alignItems="center"
                      mt={-1}
                    >
                      {/* Show search icon on mobile */}
                      <Stack
                        onClick={() => {
                          setIsSearch(!isSearch)
                        }}
                        cursor={'pointer'}
                        py={0}
                        display={{ base: 'block', md: 'none' }}
                      >
                        {isSearch ? (
                          <CloseIcon
                            color={color ? 'white' : 'black'}
                            style={{
                              width: 24,
                              height: 24
                            }}
                          />
                        ) : (
                          <SearchIcon
                            color={color ? 'white' : 'black'}
                            width={24}
                            height={24}
                          />
                        )}
                      </Stack>

                      {/* Show cart icon */}
                      <Stack position={'relative'}>
                        <Link href={RouterUrl.CART.ROOT} aria-label="Cart">
                          <JmlCartIcon
                            color="#1095d7"
                            width={mdMax ? 20 : 28}
                            height={mdMax ? 20 : 28}
                            fill="#1095d7"
                          />
                        </Link>
                        {totalItems > 0 && (
                          <Flex
                            position={'absolute'}
                            w={5}
                            h={5}
                            top={-4}
                            left={3}
                            bg={'red'}
                            color={'white'}
                            p={1}
                            borderRadius={'50%'}
                            fontSize={10}
                            alignItems={'center'}
                            justifyContent={'center'}
                            zIndex={10}
                          >
                            {totalItems}
                          </Flex>
                        )}
                      </Stack>

                      {/* Show user icon on desktop */}
                      <Box display={{ base: 'none', md: 'block' }}>
                        {customer ? (
                          <Stack>
                            <Link href={RouterUrl.ACCOUNT.ROOT}>
                              <UserIcon
                                color="#1095d7"
                                width={mdMax ? 20 : 28}
                                height={mdMax ? 20 : 28}
                                fill="#1095d7"
                              />
                            </Link>
                          </Stack>
                        ) : (
                          <Link href={RouterUrl.AUTH.LOGIN}>
                            <UserIcon
                              color="#1095d7"
                              width={mdMax ? 20 : 28}
                              height={mdMax ? 20 : 28}
                              fill="#1095d7"
                            />
                          </Link>
                        )}
                      </Box>

                      {/* Show country select on desktop */}
                      <Box display={{ base: 'none', md: 'block' }}>
                        <CountrySelectNavCustom color={color} />
                      </Box>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Container>

            <Drawer
              onClose={onClose}
              placement={'left'}
              isOpen={isOpen}
              size={'full'}
            >
              <DrawerOverlay />
              <DrawerContent bg={color ?? 'white'}>
                <DrawerBody mt={5}>
                  <Stack gap={2}>
                    <Flex justifyContent={'space-between'}>
                      <IconButton
                        onClick={onToggle}
                        _hover={{
                          bgColor: color ?? 'white'
                        }}
                        icon={
                          isOpen ? (
                            <CloseIcon
                              w={5}
                              h={5}
                              color={color ? 'white' : 'black'}
                            />
                          ) : (
                            <HamburgerIcon w={8} h={8} />
                          )
                        }
                        variant={'ghost'}
                        aria-label={'Toggle Navigation'}
                        justifyContent={'start'}
                      />

                      <HStack spacing={3}>
                        <AuthFilter filter={true} isLogin={true}>
                          <Flex
                            alignItems={'center'}
                            gap={1.5}
                            bg={'white'}
                            px={{ base: 2, sm: 2, lg: 2.5, xl: 3 }}
                            py={{ base: 1.5, sm: 1.5, lg: 1, xl: 2 }}
                            borderRadius={15}
                            onClick={verifyAdvocado}
                            cursor={'pointer'}
                          >
                            <HabbitPointIcon w={4} h={4} />
                            <Text textStyle={'joinMembership'} w={'auto'}>
                              {avocado?.avocadoInfo?.pdpa == '1' ? (
                                (
                                  avocado.avocadoInfo?.campaignBalances?.find(
                                    (item: any) =>
                                      item.campaignType == 'loyalty'
                                  ).points as number
                                ).toLocaleString()
                              ) : (
                                <div
                                  style={{
                                    cursor: 'pointer'
                                  }}
                                >
                                  Get Points & Rebate
                                </div>
                              )}
                            </Text>
                          </Flex>
                        </AuthFilter>
                        <CountrySelectNavCustom color={color} />
                      </HStack>
                    </Flex>
                    {!brand && (
                      <Link href={RouterUrl.HOME.ROOT} onClick={onClose}>
                        <Text
                          textStyle="linkItem"
                          color={
                            color ? 'white !important' : 'black !important'
                          }
                        >
                          Home
                        </Text>
                      </Link>
                    )}
                    <NavItems
                      items={navItems}
                      color={color}
                      brand={brand}
                      onClose={onClose}
                    />
                    <AuthFilter filter={true} isLogin={false}>
                      <Link href={RouterUrl.AUTH.LOGIN} onClick={onClose}>
                        <Text
                          textStyle="linkItem"
                          color={brand ? 'white' : 'black'}
                        >
                          Login
                        </Text>
                      </Link>
                    </AuthFilter>
                    <AuthFilter>
                      <Link href={RouterUrl.ACCOUNT.ROOT}>
                        <Text
                          textStyle="linkItem"
                          color={color ? 'white' : 'black'}
                        >
                          Account
                        </Text>
                      </Link>
                    </AuthFilter>
                    <AuthFilter filter={true} isLogin={true}>
                      <Link href={RouterUrl.AUTH.LOGIN} onClick={handleLogout}>
                        <Text
                          textStyle="linkItem"
                          color={color ? 'white' : 'black'}
                        >
                          Logout
                        </Text>
                      </Link>
                    </AuthFilter>
                  </Stack>
                </DrawerBody>
              </DrawerContent>
            </Drawer>
          </Box>
          {loadAdvocadoPDPAModal()}
          {loadAdvocadoSignupModal()}
        </Container>
      </Box>

      {/* Show search bar on mobile */}
      {isSearch && mdMax && (
        <Container
          bg={color ?? 'white'}
          borderTop={'1px solid #00000020'}
          py={2}
          display={'flex'}
          justifyContent={'center'}
          boxShadow={'md'}
          position={'absolute'}
          top={24}
          left={0}
          w={'100%'}
          zIndex={100}
        >
          <JmlSearch
            w={'100%'}
            colorIcon={color ? 'white' : 'black'}
            colorText={color ? 'white' : 'black'}
          />
        </Container>
      )}

      {/*  Navbar */}
      <Box bg="#01adee" px={{ lg: 2 }} w={'100%'}>
        <Container>
          <Flex
            justifyContent="space-between"
            h={mdMax ? '40px' : '50px'}
            alignItems="center"
          >
            <Flex
              gap={{
                md: customer ? 8 : 10,
                xl: customer ? 12 : 14,
                '2xl': 16
              }}
              display={{ base: 'none', md: 'flex' }}
              flex={1}
              alignItems="center"
            >
              <Link
                href={RouterUrl.HOME.ROOT}
                onClick={() => {
                  if (onClose) {
                    onClose()
                  }
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="28"
                  height="28"
                  viewBox="0 0 64 64"
                >
                  <path
                    stroke="white"
                    fill="white"
                    d="M 32 3 L 1 28 L 1.4921875 28.654297 C 2.8591875 30.477297 5.4694688 30.791703 7.2304688 29.345703 L 32 9 L 56.769531 29.345703 C 58.530531 30.791703 61.140812 30.477297 62.507812 28.654297 L 63 28 L 54 20.742188 L 54 8 L 45 8 L 45 13.484375 L 32 3 z M 32 13 L 8 32 L 8 56 L 56 56 L 56 35 L 32 13 z M 26 34 L 38 34 L 38 52 L 26 52 L 26 34 z"
                  ></path>
                </svg>
              </Link>
              <NavItems items={navItems} color="white" brand={brand} />
            </Flex>

            <Flex
              bgColor="#a83232"
              h="100%"
              alignItems="center"
              justifyContent="center"
              w={220}
            >
              <Link
                href={RouterUrl.CART.ROOT}
                onClick={() => {
                  if (onClose) {
                    onClose()
                  }
                }}
              >
                <Text fontSize={16} fontWeight={700} color="white">
                  EXCLUSIVE BUNDLES
                </Text>
              </Link>
            </Flex>
          </Flex>
        </Container>
      </Box>
    </Box>
  )
}
