import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Input,
  Spinner,
  Stack,
  Text,
  BoxProps
} from '@chakra-ui/react'
import { SearchIcon } from '@icons/search-icon'
import { useRouter } from 'next/router'
import { useFeaturedProductsQuery } from '@lib/hooks/use-layout-data'
import { IProduct } from '@common/interface/product'
import Image from 'next/image'
import useFilterQueryParams from '@common/hooks/use-filter-query-param'

const SEARCH_WIDTH = 48

const SuggestProducts = ({
  products,
  setTextSearch
}: {
  products: IProduct[]
  setTextSearch: (input: any) => void
}) => {
  const router = useRouter()
  return (
    <>
      {products.map((p) => {
        return (
          <Box
            key={p.id}
            display={'flex'}
            alignItems={'center'}
            borderBottom={'1px solid #0000000d'}
            px={3}
            py={3}
            gap={2}
            cursor={'pointer'}
            _hover={{
              bg: '#0000000d'
            }}
            onClick={() => {
              router.push(`/products/${p.handle ?? ''}`)
              setTextSearch(null)
            }}
            mt={0}
          >
            <Image
              src={p.thumbnail ?? ''}
              alt={p.title ?? 'product image'}
              width={50}
              height={50}
              quality={100}
            ></Image>
            <Text fontSize={'md'}>{p.title}</Text>
          </Box>
        )
      })}
    </>
  )
}

interface PropsType {
  colorIcon?: string
  colorText?: string
}

const JmlSearch = ({
  colorIcon,
  colorText,
  w,
  ...rest
}: {
  colorIcon?: any
  colorText?: string
} & BoxProps) => {
  const router = useRouter()

  const [textSearch, setTextSearch] = useState<string | null>(null)
  const [enter, setEnter] = useState(false)
  const { addSearch } = useFilterQueryParams()

  const { data, isLoading } = useFeaturedProductsQuery({
    limit: 10,
    offset: 0,
    q: textSearch ?? ''
  })

  useEffect(() => {
    if (router.query.q == null) {
      setTextSearch(null)
    }
  }, [router.query.q])

  const onSearchHandler = (e: any) => {
    e.preventDefault()
    setEnter(true)
    addSearch(textSearch)
  }

  return (
    <Box w="100%" position="relative">
      <Box
        border={'1px'}
        borderRadius={'30px'}
        alignItems={'center'}
        h={10}
        position={'relative'}
        borderColor={colorText}
        {...rest}
        w="100%"
      >
        <form onSubmit={onSearchHandler}>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Input
              border={'none'}
              value={textSearch ?? ''}
              w="100%"
              h={10}
              color={colorText}
              onChange={(e) => {
                if (enter) {
                  setEnter(false)
                }
                setTextSearch(e.target.value)
              }}
              _focus={{
                border: 'none',
                shadow: 'none'
              }}
            />
            <Button
              bg={'transparent'}
              _hover={{ bg: 'transparent' }}
              onClick={onSearchHandler}
              px={0}
            >
              <SearchIcon color={colorIcon} />
            </Button>
          </Box>
        </form>
      </Box>

      {textSearch && !enter && (
        <Stack
          w="100%"
          height={'auto'}
          bg={'white'}
          boxShadow={'base'}
          rounded={'md'}
          h={80}
          overflowY={'scroll'}
          zIndex={100}
          mt={2}
          gap={4}
          position={'absolute'}
          color={'black'}
        >
          {isLoading && <Spinner mx={'auto'} mt={5} />}
          {data != null && (
            <Stack w="100%" mt={0}>
              <SuggestProducts
                products={(data?.products as IProduct[]) ?? []}
                setTextSearch={(input: any) => {
                  setTextSearch(input)
                }}
              />
            </Stack>
          )}
        </Stack>
      )}
    </Box>
  )
}

export default JmlSearch
