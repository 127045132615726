import type { Head } from '../types/head'

import React from 'react'

import Footer from '@components/footer'
import Navbar from '@components/navbar'
import FacebookLivesream from '@components/facebook-livestream'
import { Box } from '@chakra-ui/react'
import Announcement from '@components/announcement'
import NotiFreeShipping from '@components/noti-free-shipping'
import MainContainer from '@components/main-container'
import {
  JML_NAV_ITEMS_MAIN_LAYOUT,
  NAV_ITEMS_MAIN_LAYOUT
} from '@constants/navbar.constant'
import { useResponsive } from '@common/hooks'
import { useRouter } from 'next/router'
import RouterUrl from '@constants/router.constant'
import Image from 'next/image'
import PageHead from '@modules/common/components/head'
import RecaptchaProvider from '@components/footer/recaptcha-provider'
import { ErrorBoundary } from 'react-error-boundary'
import FooterCopyright from '@components/footer/footer-copyright'
import JmlNavbar from '@components/jml/jml-navbar'
import { isHabbitHostname } from '@lib/util/host'

interface MainLayoutPropsType {
  children: React.ReactNode
  head?: Omit<Head, 'route'>
  notRenderHead?: boolean
}

const MainLayout = ({
  children,
  head,
  notRenderHead = false
}: MainLayoutPropsType) => {
  const { mdMax, xlMax } = useResponsive()

  const router = useRouter()

  const is500ErrorPage = router.pathname === RouterUrl['500']

  const marginTop = () => {
    if (isHabbitHostname()) {
      return xlMax ? '78px' : '96px'
    }

    return mdMax ? '118px' : '146px'
  }
  return (
    <>
      {!notRenderHead && <PageHead {...head} />}
      <Box>
        {/* Render navbar based on hostname (habbit or jml) */}
        {isHabbitHostname() ? (
          <Navbar
            navItems={NAV_ITEMS_MAIN_LAYOUT}
            productSection={null}
            logo={
              <Image
                src={'/images/habbit-logo-transition.svg'}
                width={xlMax ? 115 : 165}
                height={xlMax ? 65 : 45}
                alt="habbit-logo-icon"
              />
            }
            onRedirect={() => router.push(RouterUrl.HOME.ROOT)}
          />
        ) : (
          <JmlNavbar
            productSection={null}
            navItems={JML_NAV_ITEMS_MAIN_LAYOUT}
            onRedirect={() => router.push(RouterUrl.HOME.ROOT)}
          />
        )}

        <MainContainer
          minH={is500ErrorPage ? '50vh' : '100vh'}
          display={'flex'}
          flexDirection={'column'}
          mt={marginTop()}
        >
          <ErrorBoundary fallback={<></>}>
            <NotiFreeShipping />
            <Announcement />
            <FacebookLivesream />
          </ErrorBoundary>
          {children}
        </MainContainer>
        <ErrorBoundary fallback={<FooterCopyright />}>
          <RecaptchaProvider>
            <Footer
              subscribeColor={isHabbitHostname() ? undefined : '#7b7b7b'}
            />
          </RecaptchaProvider>
        </ErrorBoundary>
      </Box>
    </>
  )
}

export default MainLayout
